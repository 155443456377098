import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVue } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import Vuesax from 'vuesax'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Axios Mock Adapter
import '@/@fake-db/db'

//Vuesax styles
import 'vuesax/dist/vuesax.css'

const opts = {
  icons: {
    iconfont: "mdi"
  }
};
Vue.use(Vuetify);
// BSV Plugin Registration
Vue.use(BootstrapVue)
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(Vuesax)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
require('@core/assets/fonts/nunito-sans.css')
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue();

new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(opts),
  render: h => h(App),
}).$mount('#app')
