import Vue from 'vue'

import { initialAbility } from '@/libs/acl/config'
// axios
import axios from 'axios'
import ability from '../libs/acl/ability'
import router from '../router/index'
import store from '../store/index'
// import '@/libs/sweet-alerts'

import VueSweetalert2 from 'vue-sweetalert2'
const axiosIns = axios.create({
  headers: { "Content-Type": "application/json" ,'Authorization': 'Jwt '+localStorage.accessToken},
  withCredentials: true,
})
axiosIns.interceptors.request.use((config) => {
  if(!config.url.includes('/offers/create') 
  && !config.url.includes('/offers/update') 
  && !config.url.includes('application/index') 
  && !config.url.includes('mediahub/create') 
  && !config.url.includes('mediahub/update') 
  && !config.url.includes('/file-upload/create') 
  && !config.url.includes('/user-info/create')
  && !config.url.includes('/user-info/update')
 ){
const defaultPayload = {app_id : JSON.parse(localStorage.application).id};
  // Modify the config object to add your default payload
  config.data = {
    ...config.data,  // If you want to merge with existing data
  ...defaultPayload,
  };

}
return config;
  
}, (error) => {
  return Promise.reject(error);
});

axiosIns.interceptors.response.use(
  response => {
    // Return response if successful
    return response
  },
  error => {
    // Handle errors here
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if(error.response.status == 401){
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('application');
        localStorage.removeItem('applications');
        localStorage.removeItem('userData')
        ability.update(initialAbility)
        setTimeout(() => {
          store.commit('clearAllState');
        }, 200);
        router.push({ name: 'auth-login' })
      }

        if(error.response.status == 403){
          console.error(error.name,':',error.response.data.message)
      }
      // console.log('Response Error1:', error.response.status, error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      // console.log('Request Error2:', error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      // console.log('General Error3:', error.message)
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
