export default [
  {
    path: '/clients-and-stores',
    name: 'clients-and-stores',
    component: () => import('@/views/pages/clients-and-stores/ClientsAndStores.vue'),
    meta: {
      AppDrop:false,
    }
  },
  {
    path: '/monetization',
    name: 'Monetization',
    component: () => import('@/views/pages/clients-and-stores-charts/ClientsAndStoresCharts.vue'),
    meta: {
      AppDrop:false,
    }
  },
  { 
    path: '/Monetization-view/:id',
    name: 'Monetization-view',
    component: () => import('@/views/pages/clients-and-stores-charts/ClientsAndStoresChartView.vue'),
    meta: {
      AppDrop:false,
    }
  },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/pages/categories/Categories.vue'),
    },  
    {
      path: '/offers',
      name: 'offers',   
      component: () => import('@/views/pages/offers/Offers.vue'),
    }, 
    {
      path: '/media-hub',
      name: 'media-hub',
      component: () => import('@/views/pages/media-hub/Media-Hub.vue'),

    }, 
    {
      path: '/deals',
      name: 'deals',
      component: () => import('@/views/pages/deals/Deals.vue'),
    }, 
    {
      path: '/coupon-suggestion',
      name: 'coupon-suggestion',
      component: () => import('@/views/pages/coupon-suggestion/Coupon-Suggestion.vue'),
    }, 
    {
      path: '/ad-placement',
      name: 'ad-placement',
      component: () => import('@/views/pages/ad-placement/Ad-Placement.vue'),
    }, 
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import('@/views/pages/subscription/Subscription.vue'),
      meta: {
        AppDrop:false,
      }
    }, 
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/pages/user/User.vue'),
    }, 
    {
      path: '/help',
      name: 'help',
      component: () => import('@/views/pages/help/Help.vue'),
    }, 
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/pages/reports/Reports.vue'),
    }, 
    {
      path: '/app-settings',
      name: 'app-settings',
      component: () => import('@/views/pages/app-settings/App-Settings.vue'),
    }, 
    {
      path: '/ecommerce',
      name: 'ecommerce',
      component: () => import('@/views/pages/ecommerce/Ecommerce.vue'),
    }, 
    {
      path: '/contact-us',
      name: 'contact-us',
      component: () => import('@/views/pages/contact-us/ContactUs.vue'),
    }, 
    {
      path: '/product',
      name: 'product',
      component: () => import('@/views/pages/product/Product.vue'),
    },
    {
      path: '/comment',
      name: 'comment',
      component: () => import('@/views/pages/comment/Comment.vue'),
    },
    {
      path: '/suggestion',
      name: 'suggestion',
      component: () => import('@/views/pages/suggestion/Suggestion.vue'),
    },
    {
      path: '/complaints',
      name: 'complaints',
      component: () => import('@/views/pages/complaints/Complaints.vue'),
    },
    {
      path: '/cashback-program',
      name: 'cashback-program',
      component: () => import('@/views/pages/cashback-program/Cashback-Program.vue'),
    },
    {
      path: '/reported-coupons',
      name: 'reported-coupons',
      component: () => import('@/views/pages/reported-coupons/ReportedCoupons.vue'),
      meta: {
        AppDrop:false,
      }
    },
    {
      path: '/ranking',
      name: 'ranking',
      component: () => import('@/views/pages/ranking/Ranking.vue'),
    },
    {
      path: '/challenge',
      name: 'challenge',
      component: () => import('@/views/pages/challenge/Challenge.vue'),
    },
    // {
    //   path: '/sequence-management',
    //   name: 'sequence-management',
    //   component: () => import('@/views/pages/challenge/Challenge.vue'),
    // },
    // {
    //   path: '/update-homepage-status',
    //   name: 'update-homepage-status',
    //   component: () => import('@/views/pages/challenge/Challenge.vue'),
    // },
    // {
    //   path: '/update-background-color',
    //   name: 'update-background-color',
    //   component: () => import('@/views/pages/challenge/Challenge.vue'),
    // },
]
